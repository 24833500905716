export default {
    WITHDRAW_WALLET_FUND: "/EscrowBackend/api/wallet/withdraw",
    GET_WALLET_HISTORY: "/EscrowBackend/api/wallet/getTransactionsHistory",
    GET_WALLET: "/EscrowBackend/api/wallet/getWallets",
    ADD_TRANSACTION_PIN: "/EscrowBackend/api/userAccount/addTransactionPin",
    VALIDATE_TRANSACTION_PIN: "/EscrowBackend/api/userAccount/validateTransactionPin",
    CREATE_WALLET: "/EscrowBackend/api/wallet/createWallet",
    
  };
  
  export const ErrorMessages = {
    getHistoryFailed: "We are currently unable to get your wallet details",
  };

  