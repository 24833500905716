import OverviewCard from "components/dashboardComponent/OverviewCard";
import InputField from "components/blocks/InputField";
import SaleGraph from "components/dashboardComponent/SaleGraph";
import WelcomeHeader from "components/dashboardComponent/WelcomeHeader";
import ModalComponent from "components/dashboardComponent/ModalComponent";
import { LoaderProcessing } from "components/shared";
import User from "components/vectors/User";
import { PepperestWebServices } from "libs/constants";
import { currencyFormatter, handleDownload, handleCopy } from "libs/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  downloadSalesReport,
  downloadSalesReportComplete,
  getDashboardMetrics,
  getRecentOrders,
  getSalesReport,
  getTopCustomers,
} from "store/actions/dashboard";
import { getWallet } from "store/actions/wallet";
import { getPendingPayments } from "store/actions/payments";
import Settings from "../Settings";
import WithdrawWalletFund from "components/dashboardComponent/WithdrawFund";
import ViewWalletHistory from "components/dashboardComponent/ViewWalletHistory";
import RaiseWalletIssue from "components/dashboardComponent/RaiseWalletIssue"
import CreateWallet from "components/dashboardComponent/CreateWallet";
import WalletCard from "components/dashboardComponent/WalletCard";

const ConnectToFacebookStore = "EscrowBackend/auth/facebook";

const Overview = () => {
  const [salesGraphMode, setSalesGraphMode] = useState("week");
  const [withdraw, setWithdraw] = useState(false);
  const [loadWalletHistory, setLoadWalletHistory] = useState(false);
  const banks = [{ name: "uba bank" }, { name: "access bank" }];
  const [activeWalletTab, setActiveWalletTab] = useState("vfd");

  const [raiseIssue, setRaiseIssue] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [ref,setRef] = useState(null)


  
  const handleWalletTabChange = (tab) => {
    setActiveWalletTab(tab);
  };
  


  const { loadingPendingPayments,pending_payments } = useSelector(
    (state) => state.payments
  );


  const history = useHistory();
  const { userProfile, loading: loadingProfile } = useSelector(
    (state) => state.userAccount
  );
  const dispatch = useDispatch();
  const {
    overview,
    loading,
    loadingReports,
    error,
    topCustomers,
    recentOrders,
    salesReports,
    download_report_url,
  } = useSelector((state) => state.dashboard);

  const { wallets } = useSelector((state) => state.wallets);

  

  useEffect(() => {
    dispatch(getDashboardMetrics());
    dispatch(getTopCustomers());
    dispatch(getRecentOrders());
    dispatch(getSalesReport());
    dispatch(getWallet());
    dispatch(getPendingPayments("Active"));
  }, []);

  
  const { withDrawnFund } = useSelector(
    (state) => state.wallets
  );

  useEffect(()=>{
    dispatch(getWallet());
  },[withDrawnFund])

  const handleSalesGraphMode = (mode) => {
    setSalesGraphMode(mode);
  };
  const loginWithFacebook = () => {
    const windowOptions = "width=600,height=400,left=100,top=100";
    window.open(
      `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`,
      "Share",
      windowOptions
    );
    // window.location.href = `${PepperestWebServices.HOST}/${ConnectToFacebookStore}`;
  };

  const downloadReport = () => {
    dispatch(
      downloadSalesReport({
        type: "All",
        period: salesGraphMode,
      })
    );
  };

  useEffect(() => {
    if (download_report_url) {
      handleDownload(`${salesGraphMode}_sales_report`, download_report_url);
      dispatch(downloadSalesReportComplete());
    }
  }, [download_report_url]);



const getObjectByProvider = (providerName) => {
    if (wallets?.length > 0 ) {
      return wallets?.find(item => item.provider === providerName);
    }else{
      
      return null;
    }
  
};

const mozfinWallet = getObjectByProvider('mozfin'); 
const vfdWallet = getObjectByProvider('vfd'); 


  return (
    <main className="overview">
      {loading || loadingReports ? (
        <div className="loader__container">
          <LoaderProcessing />
        </div>
      ) : error ? (
        <div className="empty__state">
          <p>{error}</p>
        </div>
      ) : (
        <>
          {/* {!userProfile?.isPremium ||
           !userProfile?.bankName ||
          !userProfile?.email_verified ? (
            <Settings />
          ) : (
            <> */}
              {/* <WelcomeHeader handleClick={loginWithFacebook} /> */}
              <section className="gridlayout">
                <div className="firstgrid">
                  <div className="overview__metrics">
                    <OverviewCard
                      title="Total products"
                      type="products"
                      itemCount={overview?.total_products}
                      icon="total-product.png"
                    />
                    <OverviewCard
                      title="Total Orders"
                      type="orders"
                      itemCount={overview?.total_orders}
                      icon="total-orders.png"
                    />
                    <OverviewCard
                      title="Total customers"
                      type="customers"
                      itemCount={overview?.total_customers}
                      icon="total-customer.png"
                    />
                   

                    <OverviewCard
                      title="Total revenues"
                      type="revenues"
                      itemCount={overview?.total_revenue}
                      icon="total-revenue.png"
                    />

                  {userProfile?.isPremium ? (
                  <OverviewCard
                    title="Total Store Visit"
                    type="visits"
                    itemCount={overview?.total_visits}
                    icon="store-visit.png"
                  />
                    ) : null} 
                  </div>
                  
                  <div className="sales__report">
                    <div className="report__header">
                      <p className="title">Sales</p>
                      <div className="report__control">
                        <div className="control">
                          <p
                            className={`control__label ${
                              salesGraphMode == "week" ? "active__control" : ""
                            } `}
                            onClick={() => handleSalesGraphMode("week")}
                          >
                            This week
                          </p>
                        </div>
                        <div className="control">
                          <p
                            className={`control__label ${
                              salesGraphMode == "month" ? "active__control" : ""
                            } `}
                            onClick={() => handleSalesGraphMode("month")}
                          >
                            This month
                          </p>
                        </div>

                        {userProfile?.isPremium ? (
                          <div className="control">
                            <button
                              className="button__download__report"
                              onClick={downloadReport}
                            >
                              Download CSV
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <SaleGraph
                      salesGraphMode={salesGraphMode}
                      data={salesReports}
                    />
                  </div>
                </div>

                {/* secondgrid */}

                <section className="secondgrid">
                 <main className="wallet">
                 <div className="wallet-tab">
                 <p onClick={() => handleWalletTabChange('vfd')} className={activeWalletTab === 'vfd' ? 'active' : 'non-active'}>
                  VFD Wallet
                  </p>
                  <p onClick={() => handleWalletTabChange('mozfin')} className={activeWalletTab === 'mozfin' ? 'active' : 'non-active'}>
                   Mozfin Wallet
                  </p>
                  </div>
                  
                 {activeWalletTab === 'mozfin' && 
                 <WalletCard 
                  showCreateWallet={()=>setShowCreateWallet(true)}
                  wallet={mozfinWallet}
                  handleCopy={handleCopy}
                  userProfile={userProfile}
                  setWithdraw={setWithdraw}
                  withdraw={withdraw}
                  setLoadWalletHistory={setLoadWalletHistory}
                  name={activeWalletTab}
                   />}
                   {activeWalletTab === 'vfd' && 
                 <WalletCard 
                  showCreateWallet={()=>setShowCreateWallet(true)}
                  wallet={vfdWallet}
                  handleCopy={handleCopy}
                  userProfile={userProfile}
                  setWithdraw={setWithdraw}
                  withdraw={withdraw}
                  setLoadWalletHistory={setLoadWalletHistory}
                  name={activeWalletTab}
                   />}
                 
                 </main>
                  <div className="pending-paymentbox">
                    <p className="payment-title">Pending Payments</p>
                    
                    {pending_payments.length > 0 ? pending_payments.map((payments,index)=>(
                      <div key={index} className="payment-list">
                      <div className="userinfo">
                        <div className="user-icon">
                          <User />
                        </div>
                        <div className="username-items">
                          <p className="username">{payments.buyerName}</p>
                          <p className="items">{payments.description}</p>
                        </div>
                      </div>
                      <div className="paymentdetails">
                        <div className="amount">{currencyFormatter.format(payments.cost)}</div>
                        <div className="progress">In-progress</div>
                      </div>
                    </div>
                    )) :
                    <p className="no-pending">No pending payments found</p>
                    }
                   
                  </div>
                  {/* <div className="top__customers">
                  <div className="customer__header">
                    <p className="title">Top Customer</p>
                  </div> */}

                  {/* {topCustomers.length > 0 ? (
                    <> */}
                  {/* <div className='customer__list__header'>
                            <p>Name</p>
                        
                        </div> */}
                  {/* {topCustomers.map((customer, index) => (
                        <div className="customer__list__item" key={index}>
                          {customer?.profile_img ? (
                            <img src={customer?.profile_img} />
                          ) : (
                            <div className="user__icon">
                              <User />
                            </div>
                          )} */}

                  {/* <div>
                            <p className="name">{customer?.name}</p>
                            <p>{customer?.email}</p>
                          </div>
                        </div> */}
                  {/* ))}
                      <p className="text-center text__primary mt-4">See All</p>
                    </>
                  ) : (
                    <div className="customers">
                      <p>No customers</p>
                    </div> */}
                  {/* )}
                </div> */}
                </section>
              </section>

              <div className="recent__orders">
                <p className="title">Recent Orders</p>
                <div className="table__wrapper">
                  <table className="dashboard__table product__table table__large">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {recentOrders.length > 0 ? (
                        recentOrders?.map((order, index) => (
                          <tr key={index}>
                            <td>{order?.customerName}</td>
                            <td>
                              {order?.orderItems.map((item, index) => (
                                <span key={index}>{item?.productname}, </span>
                              ))}
                            </td>
                            <td>{currencyFormatter.format(order?.cost)}</td>
                            <td>{order?.orderItems?.length}</td>
                            <td width={150}>
                              <span
                                className={`order__status status__${order?.status?.toLowerCase()} d-block`}
                              >
                                {order?.status}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            Recent orders will appear here
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            {/* </>
          )} */}
        </>
      )}
      <ModalComponent show={withdraw} centered size="md">
        <WithdrawWalletFund
          walletProfile={activeWalletTab === 'mozfin' ? mozfinWallet : vfdWallet }
          close={() => setWithdraw(false)}
        />
      </ModalComponent>

      <ModalComponent show={loadWalletHistory} centered size="md">
        <ViewWalletHistory wallet_id={activeWalletTab === 'mozfin' ? mozfinWallet?.id : vfdWallet?.id} close={() => setLoadWalletHistory(false)} setRaiseIssue={setRaiseIssue} setRef={setRef}/>
      </ModalComponent>
      
      <ModalComponent show={raiseIssue} centered size="md">
        <RaiseWalletIssue close={() => setRaiseIssue(false)} wallet_id={activeWalletTab === 'mozfin' ? mozfinWallet?.id : vfdWallet?.id} refnum={ref}/>
      </ModalComponent>

      <ModalComponent show={showCreateWallet} centered size="md">
        <CreateWallet type={activeWalletTab} close={() => setShowCreateWallet(false)}/>
      </ModalComponent>
    </main>
  );
};

export default Overview;