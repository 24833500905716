import { CloseIcon } from "components/vectors";
import React, { useEffect, useState } from "react";
import { SelectInputWithoutLabel } from "components/blocks";
import { getWallet } from "store/actions/wallet";
import { useDispatch, useSelector } from "react-redux";


const PayOptionModal = ({close,tokenizeCard,upgradingPremium}) => {
    const [walletID,setWalletID] = useState(null)
    const [showSelect,setShowSelect] = useState(false)
    const dispatch = useDispatch()
    const { wallets } = useSelector((state) => state.wallets);

    useEffect(() => {
      dispatch(getWallet());
    }, []);

    const handlewalletID = (e)=>{
       setWalletID(e.target.value)
    }
 console.log({wallets})
  return (
    <div className="modal__component">
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
           Select a payment option
            </p>
            <span>
              <CloseIcon onClick={close} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">

      {wallets && wallets.length > 0 ?
      <>
       {!walletID ? <>
      {showSelect && <SelectInputWithoutLabel
                      options={wallets?.map((wallet)=>(
                        {
                      ...wallet,
                       value : wallet?.id.toString(), label: wallet?.provider.toString()
                      }))}
                      defaultValue="Select wallet provider"
                      onChange={handlewalletID}
                      className="nsForm-select__alternate"
                    />}

           <button
                className="button button--gray mt-4"
                onClick={()=>setShowSelect(true)}
              >
                Pay with wallet
              </button> </> :

              <button
              onClick={()=>tokenizeCard("wallet",walletID)}
              disabled={upgradingPremium}
               className="button button--green mt-4"
               
             >
               Proceed to pay with wallet
             </button>
              }

      </>
      :
      null
      }
                     

              <button
                onClick={()=>tokenizeCard()}
                disabled={upgradingPremium}
                className="button button--purple mt-4"  
              >
                Paystack
              </button>
              <br/>
          </div>    

    </div>
  );
};

export default PayOptionModal;


