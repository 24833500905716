import InputField from "components/blocks/InputField";
import { CloseIcon, BackArrow } from "components/vectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import {
  withdrawFund,
  validateTransactionPin
} from "store/actions/wallet";
import {
  getAccountName,
} from "store/actions/userAccount";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { getBanks } from "store/actions";
import { trackEvent } from "libs/utils/customer-io";


const WithdrawWalletFund = ({close,walletProfile}) => {
  const [sendingAmount,setSendingAmount] = useState(0)
  const [charges,setCharges] = useState(20)
  const [bankcode,setBankcode] = useState(null)
  const [accountNumber,setAccountNumber] = useState(null)
  const [visible, setVisible] = useState(false);
  const [withdrawDisabled, setWithdrawDisabled] = useState(false);
  const [accountError,setAccountError] = useState(null);
  const [accountVerifySuccess,setAccountVerifySuccess] = useState(null);
  const [nextScreen, setNextScreen] = useState(false);
  const [pinValue, setPinValue] = useState('');
  const [pinEror, setPinError] = useState(null);
  const [submitData, setSubmitData] = useState(null);

  const history = useHistory();

  const navigateToSetPin = (url)=>{
     resetAll()
     history.push(url);
     
  }
  

  const {
    nameEnquiry
  } = useSelector((state) => state.userAccount);

  const { banks } = useSelector((state) => state.userAccount);
  const { 
    withDrawnFund, 
    withDrawWalletError, 
    withDrawingFund,
    validateTransactionPinError,
    validatingTransactionPin,
    validatedTransactionPin,
     } = useSelector(
    (state) => state.wallets
  );
  const dispatch = useDispatch();

  const handleSendingAmount = (e)=>{
      let amount = Number(e.target.value);
      // if(amount <= 25){
      //   setCharges(25);
      //   setSendingAmount("Amount must be above ₦25")
      // }else if (amount > 25 && amount <= 50000 ){
      //     setCharges(25);
      //     setSendingAmount(amount-25)
      //   }else if(amount <= 100000){
      //     setCharges(50);
      //     setSendingAmount(amount-50)
      //   }else if(amount > 100000){
      //     setCharges(100);
      //     setSendingAmount(amount-100)
      //   }

      if(amount <= 20){
          setSendingAmount("Amount must be above ₦20")
      }else{
        //setSendingAmount(amount-20)
        setSendingAmount(amount)
      }

      
 }

 const findBankCode = (bankName) => {
  if(banks){
   const foundBank = banks.find((bank) => bank.bank === bankName);
  return foundBank ? foundBank.bankcode : null;
  }
};

const getBankCode = (e)=>{
  const bank =  findBankCode(e.target.value);
  setBankcode(bank)
  if (accountNumber ){
    dispatch(getAccountName(bank, accountNumber));
    }
}

const handleChange = (e) => {
  const acct = e.target.value;
  if(acct.length > 5 && acct.length < 10 ){
    setAccountError("Enter receiver's full account")
  }else if(acct.length === 10){ 
    if(bankcode){
      setAccountNumber(acct);
    }else{
      setAccountNumber(acct);
      setAccountError("Bank not selected (select bank)")
    } 
  }else if(acct.length > 10){
    setAccountError("Enter receiver's correct account number")
  }
 
};




useEffect(()=>{
  if (bankcode && accountNumber ){
    dispatch(getAccountName(bankcode, accountNumber));
    }
},[accountNumber])
 
const resetaccountname = ()=>{
  dispatch(getAccountName(0, 0));
}

  useEffect(()=>{
    dispatch(getBanks())
  },[])

  const walletSchema = yup.object().shape({
    bank_name: yup.string().required("Bank not selected "),
    account_no: yup.string().required("Account number is required"),
    amount: yup.string().required("Amount is required"),
    narration: yup.string()
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(walletSchema),
    mode: "all",
  });

  


  const onSubmit = (data) => {
      if (!nameEnquiry?.details?.name){
        return
      }
    data.wallet_id = walletProfile?.id
    data.account_name = nameEnquiry?.details?.name
    data.bank_code = bankcode
    setNextScreen(true)
    setSubmitData(data) 
    console.log("this is data",data)
  };


  const finalWithdraw = ()=>{
    setWithdrawDisabled(true)
    if(pinValue){
      dispatch(validateTransactionPin({pin:pinValue}));
    }else{
      setPinError("Enter a complete transaction pin")
      setWithdrawDisabled(false)
      return
    }

   
  }

  useEffect(()=>{
    if (validatedTransactionPin){
      dispatch(withdrawFund(submitData));
      }
  },[validatedTransactionPin])

  useEffect(()=>{
    if(withDrawnFund){
     reset({bank_name:'',account_no:'',amount:'',narration:''})
     resetAll()
     trackEvent("wallet_withdrawal_successful")
    }
  },[withDrawnFund])

  const resetAll = ()=>{
    setSubmitData(null);
    setPinValue('')
    setPinError(null)
    setSendingAmount(0)
    setAccountError(null)
    setAccountVerifySuccess(null)
    setAccountNumber(null)
    setSendingAmount(0)
    setNextScreen(false)
    resetaccountname()
    close()
  }

  useEffect(() => {
      if(nameEnquiry?.error && accountError){
        setAccountError(nameEnquiry?.error)
      }
  }, [nameEnquiry?.error]); 

  useEffect(() => {
    if(nameEnquiry?.details?.name){
      setAccountVerifySuccess(nameEnquiry?.details?.name)
    }else {
      setAccountVerifySuccess(null)
    }
  }, [nameEnquiry?.details?.name]); 

  useEffect(() => {
    if (withDrawWalletError || validateTransactionPinError || pinEror  ) {
      setVisible(true);
      const timer = setTimeout(() => {
        setWithdrawDisabled(false)
        setVisible(false); 
        setPinError(null)
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [withDrawWalletError,validateTransactionPinError,pinEror]);
  
  

  const handlePin = (e) => {
    const inputValue = e.target.value;
    // Only allow up to 4 digits
    if (/^\d{0,4}$/.test(inputValue)) {
      setPinValue(inputValue);
    }
  };

  return (
    <div className="modal__component">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal__title">
          <div className="d-flex justify-content-between">
            <p className="modal__header">
            Withdraw Funds
            </p>
            <span >
              <CloseIcon onClick={()=>{resetAll()}} />
            </span>
          </div>
        </div>
        <div className="modal__content modal__full">
        {
          nextScreen ? 
          <>
          <div className="form__wrapper">
          <p 
          style={{display:"flex",justifyContent:"end",fontSize:"14px",marginBottom:"7px"}}>
            No Transaction PIN ? 
            <span onClick={()=>navigateToSetPin("/dashboard/store-setting")} style={{color:"purple",fontWeight:"bold",marginLeft:"3px",cursor:"pointer"}}>
             Click here</span></p>
          {visible && <p className="error__message">
            {withDrawWalletError ? withDrawWalletError : null}
          </p>}
          {visible &&  <p className="error__message" >{validateTransactionPinError ? validateTransactionPinError :   pinEror}</p>}
            
            <InputField
             value={pinValue}
             onChange={handlePin}
             id="transaction_pin"
             label="Enter your 4 digits transaction pin"
             name="transaction_pin"
             placeHolder="Enter Transaction Pin"
             type="password"
             max="9999"
           />
           
          </div>
          <div className="modal__control control__center">
          <button
            type="button"
            className="button button--purple  px-2"
            disabled={withDrawingFund ? withDrawingFund : withdrawDisabled }
            onClick={finalWithdraw}
          >
           {withDrawingFund ? "Withdrawing...":"Withdraw Funds"}  
          </button>
          </div>
          </>
          :
          <>
          
            <div className="form__wrapper">
              <InputField
                require={true}
                id="bank_name"
                label="Select Bank"
                name="bank_name"
                options={banks?.map((item) => ({
                  label: item.bank,
                  value: item.bank,
                }))}
                register={register}
                error={errors.bank_name?.message}
                type="select"
                onChange={getBankCode}
              />
            </div>

            <div className="form__wrapper">
            <InputField
              
              onChange={handleChange}
              require={true}
              id="account_no"
              label="Enter Account Number"
              name="account_no"
              placeHolder=""
              register={register}
              error={errors.account_no?.message}
              type="number"
            />
          </div>
         {accountError  && !accountVerifySuccess && <div className="form__wrapper">
          <div className="verification_Message">
            <p>{nameEnquiry?.error ? nameEnquiry?.error : accountError}</p>
          </div>
          </div>}
          {accountVerifySuccess && <div className="form__wrapper">
          <div className="account_name">
            <p>{nameEnquiry?.details?.name}</p>
          </div>
          </div>}
          <div className="form__wrapper">
            <InputField
              
              onChange={handleSendingAmount}
              require={true}
              id="amount"
              label="Enter Amount"
              name="amount"
              placeHolder=""
              register={register}
              error={errors.amount?.message}
              type="number"
            />
          </div>
          <div className="form__wrapper">
            <div className="charges__form">
          <label className="form__label">(Amount user will receive)</label>
          <div className="charge__input">
            <p className="sending__amount">{Number(sendingAmount) >= 1 ? '₦':'' }   {sendingAmount}.00</p>
            <p className="charge__fee">Fees: ₦{charges}.00</p>
        </div>
      </div>
      </div>
      <br/>
      <div className="form__wrapper">
            <InputField
              //onChange={handleSendingAmount}
              require={false}
              id="narration"
              label="Remark(Narration)"
              name="narration"
              placeHolder=""
              register={register}
              error={errors.narration?.message}
              type="text"
            />
          </div>

          <div className="modal__control control__center">
         {/* {!accountVerifySuccess && <button
            type="button"
            className="button button--purple  px-2"
            disabled
          >
             Withdraw Funds
          </button>} */}
          { 
             accountVerifySuccess && sendingAmount >=1 &&
             <button
             type="submit"
             className="button button--purple  px-2" 
           >
             Next
           </button>
          }
        </div>
           
          </>
        }
       
            
          </div>    

      </form>
    </div>
  );
};

export default WithdrawWalletFund;

const options = [
  {
    label: "Access Bank",
    value: "Access",
  },
  {
    label: "UBA Bank",
    value: "UBA",
  },
  {
    label: "Gt Bank",
    value: "GTB",
  },
  {
    label: "Zenith Bank",
    value: "Zenith",
  },
  {
    label: "Fidelity Bank",
    value: "Fidelity",
  },
];


