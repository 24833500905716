import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  loading:false,
  withDrawWalletError: null,
  withDrawingFund: false,
  withDrawnFund: false,

  walletHistory:null,
  walletHistoryError: null,
  loadingWalletHistory: false,
  loadedWalletHistory: false,

  wallets:null,
  walletsError: null,
  loadingWallets: false,
  loadedWallets: false,

  
  addTransactionPinError: null,
  addingTransactionPin: false,
  addedTransactionPin: false,

  validateTransactionPinError: null,
  validatingTransactionPin: false,
  validatedTransactionPin: false,

  
  createWalletError: null,
  creatingWallet: false,
  createdWallet: false,
};


const loading = (state, action) => {
    return updateObject(state, {
    loading: true,
    });
  };

const withDrawFundStart = (state, action) => {
  return updateObject(state, {
    withDrawWalletError: "",
    withDrawingFund: true,
    withDrawnFund: false,
  });
};

const withDrawFundFail = (state, action) => {
  return updateObject(state, {
    withDrawWalletError: action.error,
    withDrawingFund: false,
    withDrawnFund: false,
  });
};
const withDrawFundSuccess = (state, action) => {
  return updateObject(state, {
    withDrawWalletError: "",
    withDrawingFund: false,
    withDrawnFund: true,
  });
};

const withDrawFundComplete = (state, action) => {
  return updateObject(state, {
    withDrawWalletError: "",
    withDrawingFund: false,
    withDrawnFund: false,
  });
};

const getWalletHistory = (state, action) => {
    return updateObject( state, action.update );
  };

const loadingWalletHistory = (state, action) => {
  return updateObject(state, {
    loadingWalletHistory: true,
    loadedWalletHistory:false,
    walletHistoryError: "",
  });
};
const getWalletHistorySuccess = (state, action) => {
  return updateObject(state, {
    walletHistory: action.walletHistory,
    walletHistoryError: "",
    loadedWalletHistory:true,
    loadingWalletHistory: false,
  });
};

const getWalletHistoryFail = (state, action) => {
  return updateObject(state, {
    loadedWalletHistory: false,
    walletHistoryError: action.error,
    loadingWalletHistory: false,
  });
};


const getWallet = (state, action) => {
  return updateObject( state, action.update );
};

const loadingWallet = (state, action) => {
return updateObject(state, {
  loadingWallets: true,
  loadedWallets:false,
  walletsError: "",
});
};
const getWalletSuccess = (state, action) => {
return updateObject(state, {
  wallets: action.wallets,
  walletsError: "",
  loadedWallets:true,
  loadingWallets: false,
});
};

const getWalletFail = (state, action) => {
return updateObject(state, {
  loadedWallets: false,
  WalletsError: action.error,
  loadingWallets: false,
});
};


const addTransactionPinStart = (state, action) => {
  return updateObject(state, {
    addTransactionPinError: "",
    addingTransactionPin: true,
    addedTransactionPin: false,
  });
};

const addTransactionPinFail = (state, action) => {
  return updateObject(state, {
    addTransactionPinError: action.error,
    addingTransactionPin: false,
    addedTransactionPin: false,
  });
};
const addTransactionPinSuccess = (state, action) => {
  return updateObject(state, {
    addTransactionPinError: "",
    addingTransactionPin: false,
    addedTransactionPin: true,
  });
};

const addTransactionPinComplete = (state, action) => {
  return updateObject(state, {
    addTransactionPinError: "",
    addingTransactionPin: false,
    addedTransactionPin: false,
  });
};


const validateTransactionPinStart = (state, action) => {
  return updateObject(state, {
    validateTransactionPinError: "",
    validatingTransactionPin: true,
    validatedTransactionPin: false,
  });
};

const validateTransactionPinFail = (state, action) => {
  return updateObject(state, {
    validateTransactionPinError: action.error,
    validatingTransactionPin: false,
    validatedTransactionPin: false,
  });
};
const validateTransactionPinSuccess = (state, action) => {
  return updateObject(state, {
    validateTransactionPinError: "",
    validatingTransactionPin: false,
    validatedTransactionPin: true,
  });
};

const validateTransactionPinComplete = (state, action) => {
  return updateObject(state, {
    validateTransactionPinError: "",
    validatingTransactionPin: false,
    validatedTransactionPin: false,
  });
};


const createWalletStart = (state, action) => {
  return updateObject(state, {
    createWalletError: "",
    creatingWallet: true,
    createdWallet: false,
  });
};

const createWalletFail = (state, action) => {
  return updateObject(state, {
    createWalletError: action.error,
    creatingWallet: false,
    createdWallet: false,
  });
};
const createWalletSuccess = (state, action) => {
  return updateObject(state, {
    createWalletError: "",
    creatingWallet: false,
    createdWallet: true,
  });
};

const createWalletComplete = (state, action) => {
  return updateObject(state, {
    createWalletError: "",
    creatingWallet: false,
    createdWallet: false,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WITHDRAW_FUND:
      return withDrawFundStart(state, action);
    case actionTypes.WITHDRAW_FUND_SUCCESS:
      return withDrawFundSuccess(state, action);
    case actionTypes.WITHDRAW_FUND_FAIL:
      return withDrawFundFail(state, action);
    case actionTypes.WITHDRAW_FUND_COMPLETE:
      return withDrawFundComplete(state, action);
    case actionTypes.GET_WALLET_HISTORY:
      return getWalletHistory(state, action);
    case actionTypes.GET_WALLET_HISTORY_SUCCESS:
      return getWalletHistorySuccess(state, action);
    case actionTypes.GET_WALLET_HISTORY_FAIL:
      return getWalletHistoryFail(state, action);
    case actionTypes.LOAD_WALLET_HISTORY:
      return loadingWalletHistory(state, action);
    case actionTypes.GET_WALLET:
      return getWallet(state, action);
    case actionTypes.GET_WALLET_SUCCESS:
      return getWalletSuccess(state, action);
    case actionTypes.GET_WALLET_FAIL:
      return getWalletFail(state, action);
    case actionTypes.LOAD_WALLET:
      return loadingWallet(state, action);
    case actionTypes.ADD_TRANSACTION_PIN:
      return addTransactionPinStart(state, action);
    case actionTypes.ADD_TRANSACTION_PIN_SUCCESS:
      return addTransactionPinSuccess(state, action);
    case actionTypes.ADD_TRANSACTION_PIN_FAIL:
      return addTransactionPinFail(state, action);
    case actionTypes.ADD_TRANSACTION_PIN_COMPLETE:
      return addTransactionPinComplete(state, action);
    case actionTypes.VALIDATE_TRANSACTION_PIN:
      return validateTransactionPinStart(state, action);
    case actionTypes.VALIDATE_TRANSACTION_PIN_SUCCESS:
      return validateTransactionPinSuccess(state, action);
    case actionTypes.VALIDATE_TRANSACTION_PIN_FAIL:
      return validateTransactionPinFail(state, action);
    case actionTypes.VALIDATE_TRANSACTION_PIN_COMPLETE:
      return validateTransactionPinComplete(state, action);
    case actionTypes.CREATE_WALLET:
        return createWalletStart(state, action);
    case actionTypes.CREATE_WALLET_SUCCESS:
        return createWalletSuccess(state, action);
    case actionTypes.CREATE_WALLET_FAIL:
        return createWalletFail(state, action);
    case actionTypes.CREATE_WALLET_COMPLETE:
        return createWalletComplete(state, action);
    default:
      return state;
  }
};

export default reducer;